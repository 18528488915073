export const icons = [
  'add-content',
  'apple',
  'archive',
  'arrow-circle',
  'arrow-left',
  'arrow-right',
  'arrow-rotate',
  'arrow-shuffle',
  'audio-disabled',
  'bell-notification',
  'block',
  'blood-drop-square',
  'calendar',
  'camera-front',
  'camera-photo',
  'camera-video',
  'chart-up-arrow-square',
  'chat',
  'check',
  'check-2',
  'checkmark',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'cip',
  'circle-xmark',
  'close',
  'copy-paste-select-add-plus',
  'dna',
  'doctor',
  'download',
  'drugs-pill.4',
  'dumbbell',
  'earth',
  'edit',
  'edit-pen',
  'exit-logout',
  'eye-hidden',
  'eye-visible',
  'facebook',
  'file-list',
  'file-pdf',
  'filter-sort',
  'fire',
  'folder-open',
  'google',
  'grid-layout',
  'grid-list',
  'image',
  'info',
  'kebab-menu',
  'list-medical-cross',
  'liti-logo-3',
  'location',
  'location-ban',
  'lock',
  'mail',
  'medical-folder',
  'menu-burge-square',
  'menu-burger',
  'menu-cursor-square',
  'menu-search-loupe-square',
  'message-question-checkmark',
  'message-video-edit-cc-titles',
  'mic-rec',
  'minus-circle',
  'money',
  'moon',
  'music',
  'pause',
  'phone-call',
  'pin-regular',
  'pin-solid',
  'play',
  'plus',
  'plus-circle',
  'refresh-loading',
  'reply',
  'resize-arrows',
  'scale',
  'search',
  'send-arrow-circle',
  'send-message',
  'settings',
  'severity-arrow',
  'share',
  'single-user',
  'smile-happy',
  'smile-mood',
  'smile-sad',
  'spinner',
  'sneakers',
  'stars',
  'sun',
  'target-darz-arrow',
  'trash',
  'truck',
  'user-add-plus',
  'user-profile-refresh-change',
  'user1',
  'user2',
  'user3',
  'users-profile',
  'users',
  'vegetable',
  'volume-full',
  'warning',
  'weight-diet',
  'whatsapp',
] as const

// Get the type of the array elements
export type Icon = (typeof icons)[number]
