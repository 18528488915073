import { default as indexFVPtkowGzRMeta } from "/root/project/src/pages/health/customers/[id]/inbox/index.vue?macro=true";
import { default as indexkY9MQIcoXuMeta } from "/root/project/src/pages/health/customers/[id]/index.vue?macro=true";
import { default as _91mealPlanId_93DZQJeD2SYrMeta } from "/root/project/src/pages/health/customers/[id]/meal-plans/[mealPlanId].vue?macro=true";
import { default as index0jAG56Cq2WMeta } from "/root/project/src/pages/health/customers/[id]/meal-plans/index.vue?macro=true";
import { default as _91mealPlanId_937TRKBzvPRpMeta } from "/root/project/src/pages/health/customers/[id]/plans/[mealPlanId].vue?macro=true";
import { default as indextRBJkB738pMeta } from "/root/project/src/pages/health/customers/[id]/plans/index.vue?macro=true";
import { default as _91id_93vL6gHVCa6xMeta } from "/root/project/src/pages/health/customers/[id].vue?macro=true";
import { default as indexwqjyHcYwRQMeta } from "/root/project/src/pages/health/customers/index.vue?macro=true";
import { default as indexdJflzfxN0xMeta } from "/root/project/src/pages/health/dashboard/index.vue?macro=true";
import { default as indexDeO88w7sLWMeta } from "/root/project/src/pages/health/index.vue?macro=true";
import { default as PatientTemplateVxnARW2gIEMeta } from "/root/project/src/pages/health/patients/[patientId]/components/PatientTemplate.vue?macro=true";
import { default as PatientTemplateHeaderpwjhz7oq7hMeta } from "/root/project/src/pages/health/patients/[patientId]/components/PatientTemplateHeader.vue?macro=true";
import { default as indexBYIehScgnYMeta } from "/root/project/src/pages/health/patients/[patientId]/index.vue?macro=true";
import { default as _91mealPlanId_93ILYMCsRJAbMeta } from "/root/project/src/pages/health/patients/[patientId]/meal-plans/[mealPlanId].vue?macro=true";
import { default as indexvmwz37zmCEMeta } from "/root/project/src/pages/health/patients/[patientId]/meal-plans/index.vue?macro=true";
import { default as _91patientId_9326s0jxpQytMeta } from "/root/project/src/pages/health/patients/[patientId].vue?macro=true";
import { default as healthekgRoOhMBhMeta } from "/root/project/src/pages/health.vue?macro=true";
import { default as index2e9JmqlskZMeta } from "/root/project/src/pages/index.vue?macro=true";
import { default as index5bBAgbC6pvMeta } from "/root/project/src/pages/ops/chat/index.vue?macro=true";
import { default as indexGaTCbAoXZqMeta } from "/root/project/src/pages/ops/country/index.vue?macro=true";
import { default as indexp19m1lKYXKMeta } from "/root/project/src/pages/ops/customers/[customerId]/index.vue?macro=true";
import { default as _91customerId_93ybmSeL91aOMeta } from "/root/project/src/pages/ops/customers/[customerId].vue?macro=true";
import { default as indexciSeaLVcOVMeta } from "/root/project/src/pages/ops/customers/index.vue?macro=true";
import { default as health_45staffELZ3RFEnc5Meta } from "/root/project/src/pages/ops/customers/profile/health-staff.vue?macro=true";
import { default as indexJQz4rJc4aaMeta } from "/root/project/src/pages/ops/customers/profile/index.vue?macro=true";
import { default as membersDviN7ZwDPXMeta } from "/root/project/src/pages/ops/customers/profile/members.vue?macro=true";
import { default as payment_45historyxtZbVpqgKCMeta } from "/root/project/src/pages/ops/customers/profile/payment-history.vue?macro=true";
import { default as user_45JourneyOdM1GVP5UFMeta } from "/root/project/src/pages/ops/customers/profile/user-Journey.vue?macro=true";
import { default as profile5mrfe35aZZMeta } from "/root/project/src/pages/ops/customers/profile.vue?macro=true";
import { default as index42MkvN4wR4Meta } from "/root/project/src/pages/ops/index.vue?macro=true";
import { default as index3A4dhlUj3jMeta } from "/root/project/src/pages/ops/meal-plans/index.vue?macro=true";
import { default as _91mealPlanId_93C251FSKpZzMeta } from "/root/project/src/pages/ops/meal-plans/templates/[mealPlanId].vue?macro=true";
import { default as index770vjEZ4vHMeta } from "/root/project/src/pages/ops/meal-plans/templates/index.vue?macro=true";
import { default as _91planId_93AQfs9d9B9eMeta } from "/root/project/src/pages/ops/plans/[planId].vue?macro=true";
import { default as indexhO8M9ecuWdMeta } from "/root/project/src/pages/ops/plans/index.vue?macro=true";
import { default as indexJzUsSyFzUeMeta } from "/root/project/src/pages/ops/settings/index.vue?macro=true";
import { default as indexVLw7PrsTpEMeta } from "/root/project/src/pages/ops/staff/[staffId]/index.vue?macro=true";
import { default as indexAuibyGZcUNMeta } from "/root/project/src/pages/ops/staff/index.vue?macro=true";
import { default as opsTQrOR3HliqMeta } from "/root/project/src/pages/ops.vue?macro=true";
export default [
  {
    name: healthekgRoOhMBhMeta?.name,
    path: "/health",
    meta: healthekgRoOhMBhMeta || {},
    component: () => import("/root/project/src/pages/health.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93vL6gHVCa6xMeta?.name,
    path: "customers/:id()",
    meta: _91id_93vL6gHVCa6xMeta || {},
    component: () => import("/root/project/src/pages/health/customers/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "health-customers-id-inbox",
    path: "inbox",
    meta: indexFVPtkowGzRMeta || {},
    component: () => import("/root/project/src/pages/health/customers/[id]/inbox/index.vue").then(m => m.default || m)
  },
  {
    name: "health-customers-id",
    path: "",
    component: () => import("/root/project/src/pages/health/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "health-customers-id-meal-plans-mealPlanId",
    path: "meal-plans/:mealPlanId()",
    component: () => import("/root/project/src/pages/health/customers/[id]/meal-plans/[mealPlanId].vue").then(m => m.default || m)
  },
  {
    name: "health-customers-id-meal-plans",
    path: "meal-plans",
    meta: index0jAG56Cq2WMeta || {},
    component: () => import("/root/project/src/pages/health/customers/[id]/meal-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "health-customers-id-plans-mealPlanId",
    path: "plans/:mealPlanId()",
    component: () => import("/root/project/src/pages/health/customers/[id]/plans/[mealPlanId].vue").then(m => m.default || m)
  },
  {
    name: "health-customers-id-plans",
    path: "plans",
    meta: indextRBJkB738pMeta || {},
    component: () => import("/root/project/src/pages/health/customers/[id]/plans/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "health-customers",
    path: "customers",
    component: () => import("/root/project/src/pages/health/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "health-dashboard",
    path: "dashboard",
    meta: indexdJflzfxN0xMeta || {},
    component: () => import("/root/project/src/pages/health/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "health",
    path: "",
    meta: indexDeO88w7sLWMeta || {},
    component: () => import("/root/project/src/pages/health/index.vue").then(m => m.default || m)
  },
  {
    name: _91patientId_9326s0jxpQytMeta?.name,
    path: "patients/:patientId()",
    meta: _91patientId_9326s0jxpQytMeta || {},
    component: () => import("/root/project/src/pages/health/patients/[patientId].vue").then(m => m.default || m),
    children: [
  {
    name: "health-patients-patientId-components-PatientTemplate",
    path: "components/PatientTemplate",
    component: () => import("/root/project/src/pages/health/patients/[patientId]/components/PatientTemplate.vue").then(m => m.default || m)
  },
  {
    name: "health-patients-patientId-components-PatientTemplateHeader",
    path: "components/PatientTemplateHeader",
    component: () => import("/root/project/src/pages/health/patients/[patientId]/components/PatientTemplateHeader.vue").then(m => m.default || m)
  },
  {
    name: "health-patients-patientId",
    path: "",
    component: () => import("/root/project/src/pages/health/patients/[patientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "health-patients-patientId-meal-plans-mealPlanId",
    path: "meal-plans/:mealPlanId()",
    component: () => import("/root/project/src/pages/health/patients/[patientId]/meal-plans/[mealPlanId].vue").then(m => m.default || m)
  },
  {
    name: "health-patients-patientId-meal-plans",
    path: "meal-plans",
    meta: indexvmwz37zmCEMeta || {},
    component: () => import("/root/project/src/pages/health/patients/[patientId]/meal-plans/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index2e9JmqlskZMeta || {},
    component: () => import("/root/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: opsTQrOR3HliqMeta?.name,
    path: "/ops",
    meta: opsTQrOR3HliqMeta || {},
    component: () => import("/root/project/src/pages/ops.vue").then(m => m.default || m),
    children: [
  {
    name: "ops-chat",
    path: "chat",
    meta: index5bBAgbC6pvMeta || {},
    component: () => import("/root/project/src/pages/ops/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-country",
    path: "country",
    meta: indexGaTCbAoXZqMeta || {},
    component: () => import("/root/project/src/pages/ops/country/index.vue").then(m => m.default || m)
  },
  {
    name: _91customerId_93ybmSeL91aOMeta?.name,
    path: "customers/:customerId()",
    component: () => import("/root/project/src/pages/ops/customers/[customerId].vue").then(m => m.default || m),
    children: [
  {
    name: "ops-customers-customerId",
    path: "",
    component: () => import("/root/project/src/pages/ops/customers/[customerId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ops-customers",
    path: "customers",
    meta: indexciSeaLVcOVMeta || {},
    component: () => import("/root/project/src/pages/ops/customers/index.vue").then(m => m.default || m)
  },
  {
    name: profile5mrfe35aZZMeta?.name,
    path: "customers/profile",
    component: () => import("/root/project/src/pages/ops/customers/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "ops-customers-profile-health-staff",
    path: "health-staff",
    component: () => import("/root/project/src/pages/ops/customers/profile/health-staff.vue").then(m => m.default || m)
  },
  {
    name: "ops-customers-profile",
    path: "",
    meta: indexJQz4rJc4aaMeta || {},
    component: () => import("/root/project/src/pages/ops/customers/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-customers-profile-members",
    path: "members",
    component: () => import("/root/project/src/pages/ops/customers/profile/members.vue").then(m => m.default || m)
  },
  {
    name: "ops-customers-profile-payment-history",
    path: "payment-history",
    component: () => import("/root/project/src/pages/ops/customers/profile/payment-history.vue").then(m => m.default || m)
  },
  {
    name: "ops-customers-profile-user-Journey",
    path: "user-Journey",
    component: () => import("/root/project/src/pages/ops/customers/profile/user-Journey.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ops",
    path: "",
    meta: index42MkvN4wR4Meta || {},
    component: () => import("/root/project/src/pages/ops/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-meal-plans",
    path: "meal-plans",
    meta: index3A4dhlUj3jMeta || {},
    component: () => import("/root/project/src/pages/ops/meal-plans/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-meal-plans-templates-mealPlanId",
    path: "meal-plans/templates/:mealPlanId()",
    component: () => import("/root/project/src/pages/ops/meal-plans/templates/[mealPlanId].vue").then(m => m.default || m)
  },
  {
    name: "ops-meal-plans-templates",
    path: "meal-plans/templates",
    meta: index770vjEZ4vHMeta || {},
    component: () => import("/root/project/src/pages/ops/meal-plans/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-plans-planId",
    path: "plans/:planId()",
    component: () => import("/root/project/src/pages/ops/plans/[planId].vue").then(m => m.default || m)
  },
  {
    name: "ops-plans",
    path: "plans",
    meta: indexhO8M9ecuWdMeta || {},
    component: () => import("/root/project/src/pages/ops/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-settings",
    path: "settings",
    meta: indexJzUsSyFzUeMeta || {},
    component: () => import("/root/project/src/pages/ops/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-staff-staffId",
    path: "staff/:staffId()",
    component: () => import("/root/project/src/pages/ops/staff/[staffId]/index.vue").then(m => m.default || m)
  },
  {
    name: "ops-staff",
    path: "staff",
    meta: indexAuibyGZcUNMeta || {},
    component: () => import("/root/project/src/pages/ops/staff/index.vue").then(m => m.default || m)
  }
]
  }
]